<template>
  <section class="page-bg">
    <div class="banner page-container">
<!--      <p>音声対話AIクラウドプラットフォーム</p>-->
    </div>
    <div class="services asr page-container">
      <div class="services-bg">
        <img src="~@/assets/images/services/asr_e.png">
      </div>
      <div class="services-intro">
        <h3>音声認識</h3>
        <p>音声をテキストに変換するサービスは、ワンフレーズ認識、リアルタイム音声認識、録音ファイルの書き起こしなどのAPIを提供し、企業内部研修、官公庁会議など様々なシーンで幅広く活用できます。</p>
        <div>
          <!-- <p>一般的なアプリケーション</p> -->
          <div>
            <div>
              <p><img src="~@/assets/images/services/asr_1.png"></p>
              <span>リアルタイム<br>音声認識</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/asr_2.png"></p>
              <span>録音ファイルの<br>書き起こし</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services tts page-container">
      <div class="services-intro">
        <h3>音声合成</h3>
        <p>文字を音声に変換するサービスです。短いテキストのオンライン合成、オフライン音声合成などのAPIを提供しており、高速かつ質の高い音声を合成し、人間と機械のコミュニケーションをより自然的にできます。</p>
        <div>
          <!-- <p>一般的なアプリケーション</p> -->
          <div>
            <div>
              <p><img src="~@/assets/images/services/tts_1.png"></p>
              <span>短いテキストの<br>オンライン合成</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/tts_2.png"></p>
              <span>オフライン<br>音声合成</span>
            </div>
          </div>
        </div>
      </div>
      <div class="services-bg">
        <img src="~@/assets/images/services/asr.png">
      </div>
    </div>
    <div class="services engine page-container">
      <div class="services-bg">
        <img src="~@/assets/images/services/nmt.png">
      </div>
      <div class="services-intro">
        <h3>Dolphin大規模言語モデル</h3>
        <p>人間のメカニズムに基づき、言語の情報を言語学、心理学、脳神経科学と融合させ、大規模言語モデルに基づいて発話意図を深く理解、リアクションすることを実現。質問応答やコンテンツ作成などに幅広く活用できる。</p>
        <div>
          <!-- <p>一般的なアプリケーション</p> -->
          <div>
            <div>
              <p><img src="~@/assets/images/services/dolphin_1.png"></p>
              <span>内容生成</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/dolphin_2.png"></p>
              <span>質問応答</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <h2 class="page-container">製品の優位性</h2>
      <div class="adv_intro page-container">
        <p>多言語対応</p>
        <p>カスタマ開発</p>
        <p>フルチェーン技術</p>
        <p>On-Premise対応</p>
      </div>
      <div class="adv-details">
        <div class="details-left" ref="detailsLeft">
          <p><img ref="liImg" src="~@/assets/images/pro_name.png"></p>
          <ul id="ulist">
            <li ref="detailsLi" @click.prevent="advScroll($event)"><a id="firstLine_a" data-index="0">多言語対応</a></li>
            <li @click.prevent="advScroll($event)"><a id="secondLine_a" data-index="1">カスタマ開発</a></li>
            <li @click.prevent="advScroll($event)"><a id="thirdLine_a" data-index="2">フルチェーン技術</a></li>
            <li @click.prevent="advScroll($event)"><a id="forthLine_a" data-index="3">On-Premise対応</a></li>
          </ul>
        </div>
        <div class="details-right">
          <el-timeline>
            <el-timeline-item id="firstLine" timestamp="多言語対応" placement="top">
              <el-card>
                <h4>日本語、英語、中国語、韓国語、フランス語、スペイン語、ロシア語、ドイツ語、チベット語、タイ語、ベトナム語など20以上の言語に対応しています。</h4>
                <div class="phs">
                  <p class="engine01"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="secondLine" timestamp="カスタマ開発" placement="top">
              <el-card>
                <h4>顧客のニーズに応じてエンジンのカスタマイズチューニングサービスも提供できます。</h4>
                <div class="phs">
                  <p class="engine02"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="thirdLine" timestamp="フルチェーン技術" placement="top">
              <el-card>
                <h4>音声認識、合成、LLMだけではなく、意味理解、テキスト生成、機械翻訳、声紋認識の音声対話フルチェーン独自技術持ってます。</h4>
                <div class="phs">
                  <p class="engine03"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="forthLine" timestamp="On-Premise対応" placement="top">
              <el-card>
                <h4>お客様のセキュリティや複雑構成などの要望に合わせて、ご指定いただく環境でのOn-Premise構築のサービスも可能です。</h4>
                <div class="phs">
                  <p class="engine04"></p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="contact page-container" @mouseenter="handleHover(true)" @mouseleave="handleHover(false)">
      <div class="contact-center">
        <div class="contacts-btn" @click="$router.push('/contact')">協力相談</div>
        <p>お問い合わせいただければ幸いです</p>
      </div>

<!--      <div class="contact-left">-->
<!--        <h6>協力相談</h6>-->
<!--        <b ref="hoverEl"></b>-->
<!--        <p>お問い合わせいただければ幸いです</p>-->
<!--      </div>-->
<!--      <div class="contact-btn" @click="$router.push('/contact')">-->
<!--        <img src="~@/assets/images/services/contact.png">-->
<!--      </div>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'ai-engine',
  mounted () {
    window.onscroll = () => {
      this.pageScroll()
      this.pageScroll_li()
    }
    this.$nextTick(() => {
      let list = document.getElementById('ulist').children
      this.ulist = []
      for(let i = 0; i < list.length; i++) {
        this.ulist.push(list[i].innerText)
      }
    })
  },
  beforeDestroy () {
    window.onscroll = null
  },
  methods: {
    pageScroll () {
      let imgs = document.getElementsByClassName('services-bg')
      let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      for (let i = 0; i < imgs.length; i++) {
        let img1Top = imgs[i].getBoundingClientRect().top
        if (img1Top < h-300) {
          imgs[i].classList.add("scroll-top")
          imgs[i].classList.remove("scroll-bottom")
        } else {
          imgs[i].classList.add("scroll-bottom")
          imgs[i].classList.remove("scroll-top")
        }
      }
    },
    handleHover (bol) {
      if (bol) {
        this.$refs.hoverEl.classList.add('line-enter')
        this.$refs.hoverEl.classList.remove('line-leave')
      } else {
        this.$refs.hoverEl.classList.add('line-leave')
        this.$refs.hoverEl.classList.remove('line-enter')
      }
    },
    pageScroll_li () {
      let firstLine = document.getElementById('firstLine').getBoundingClientRect().top
      let firstLine_a = document.getElementById('firstLine_a').getBoundingClientRect().top
      let secondLine = document.getElementById('secondLine').getBoundingClientRect().top
      let secondLine_a = document.getElementById('secondLine_a').getBoundingClientRect().top
      let thirdLine = document.getElementById('thirdLine').getBoundingClientRect().top
      let thirdLine_a = document.getElementById('thirdLine_a').getBoundingClientRect().top
      let forthLine = document.getElementById('forthLine').getBoundingClientRect().top
      let forthLine_a = document.getElementById('forthLine_a').getBoundingClientRect().top
      let lis = document.getElementById('ulist').children
      if (firstLine_a-50 < firstLine&&firstLine < firstLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[0].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (firstLine_a < secondLine&&secondLine < thirdLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[1].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (secondLine_a < thirdLine&&thirdLine < forthLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[2].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (thirdLine_a < forthLine&&forthLine < forthLine_a+50) {
        this.$refs.liImg.style.transform = `translateY(${lis[3].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
    },
    advScroll (event) {
      let element = this.$refs.detailsLeft;
      let computedStyles = window.getComputedStyle(element);
      let detailsLi = this.$refs.detailsLi;
      let detailsLiStyles = window.getComputedStyle(detailsLi)
      let H = parseInt(detailsLiStyles.lineHeight.split('px')[0])+parseInt(detailsLiStyles.marginBottom.split('px')[0])
      let i = event.target.getAttribute('data-index')
      let list = document.getElementsByClassName('el-timeline-item')
      let targetPosition = list[i].offsetTop;
      let www = targetPosition-computedStyles.top.split('px')[0]-i*H
      window.scrollTo({
        top: www,
        behavior: "smooth"
      });
      this.pageScroll_li()
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  background: url('../assets/images/services/dolphin_banner.jpg') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  >p {
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 93.76px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
}
.services {
  position: relative;
  height: 720px;
  background: url('../assets/images/services/engine_pro_bg.jpg') no-repeat left top;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.tts {
  background: url('../assets/images/services/tts_e_bg.png') no-repeat right top;
  background-size: 605px 734px;
  padding-right: 0;
  color: #000;
  overflow: hidden;
}
.services-intro {
  flex: 1;
  color: inherit;
  h3 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 46px;
    color: inherit;
    text-align: left;
    vertical-align: top;
    margin-bottom: 30px;
  }
  >p {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 48px;
    color: inherit;
    text-align: left;
    vertical-align: top;
    margin-bottom: 50px;
  }
  >div >p {
    height: 40px;
    padding-left: 56px;
    background: url('../assets/images/see_more.png') no-repeat left top;
    background-size: 40px 40px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    color: inherit;
    margin-bottom: 24px;
  }
  >div >div {
    height: 128px;
    display: flex;
    >div {
      width: 274px;
      height: 80px;
      padding: 24px;
      border-radius: 27px;
      background: rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      margin-right: 20px;
      p, p>img {
        width: 80px;
        height: 80px;
      }
      span {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0;
        color: inherit;
        padding-left: 20px;
      }
    }
  }
}
.services-bg {
  width: 613px;
  height: 409px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 28px;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.2);
  }
}
.services-bg.scroll-top img {
  transform: scale(1);
  transition: transform 0.3s linear;
}
.services-bg.scroll-bottom img {
  transform: scale(1.2);
  transition: transform 0.3s linear;
}
.asr {
  padding-left: 0;
  .services-bg {
    width: 571px;
    position: relative;
    left: -55px;
    transform: translateY(50%);
    margin-right: 78px;
  }
}
.tts .services-bg {
  position: relative;
  right: 0;
  width: auto;
  height: 500px;
  margin-left: 78px;
  margin-right: 280px;
}
.tts .services-intro >div >p {
  background-image: url('../assets/images/pro_name.png')
}
.engine {
  padding-left: 0;
}
.engine .services-bg {
  width: 540px;
  height: auto;
  position: relative;
  bottom: -68px;
  border-radius: 0 30px 30px 0;
  overflow: hidden;
  margin-right: 110px;
}
.advantage {
  min-height: 300px;
  padding-top: 150px;
  padding-bottom: 100px;
  h2 {
    font-weight: 500;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 93.76px;
    color: rgba(47, 41, 223, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 50px;
  }
  .adv_intro {
    height: 300px;
    margin-bottom: 102px;
    display: flex;
    p {
      width: 224px;
      height: 300px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 38px;
      padding-right: 38px;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 59px;
      color: rgba(60, 60, 60, 1);
      text-align: center;
      vertical-align: top;
      background: url('../assets/images/services/proAdv_1.png') no-repeat left top;
      background-size: 100% 100%;
      position: relative;
      right: -4px;
    }
    p:nth-of-type(2) {
      background-image: url('../assets/images/services/proAdv_2.png');
      right: 27px;
    }
    p:nth-of-type(3) {
      background-image: url('../assets/images/services/proAdv_3.png');
      left: -58px;
    }
    p:nth-of-type(4) {
      background-image: url('../assets/images/services/proAdv_4.png');
      left: -89px;
    }
  }
  .adv-details {
    min-height: 100px;
    display: flex;
    padding-left: 165px;
    .details-left {
      width: 300px;
      height: 160px;
      display: flex;
      flex-shrink: 0;
      margin-right: 40px;
      margin-left: 10px;
      position: sticky;
      top: 213px;
      >p, >p img {
        width: 16px;
        height: 16px;
      }
      >p {
        height: 160px;
        margin-right: 12px;
        padding-top: 4px;
      }
      ul li {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: left;
        vertical-align: top;
        cursor: pointer;
      }
      ul li:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    .move-left {
      transition: transform 0.3s linear;
    }
    .details-right {
      flex: 1;
    }
  }
}
</style>
